import { LazyExoticComponent } from 'react'
import { createRoot, Root } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import sikaTheme from '../../theme'

export default (
  reactRootMap: Map<Element, Root>,
  // eslint-disable-next-line no-empty-pattern
  Component: LazyExoticComponent<any> | (({}: any) => JSX.Element),
  domId: string,
  props?: object,
): void => {
  if (!Component) {
    throw new Error('No component exists')
  }
  // NOTE: we are getting a nodelist of elements so that if there is more than one
  // of the same element on the page, they will all render properly
  const elements = document.querySelectorAll(`[id=${domId}]`)
  elements.forEach((element) => {
    let root = reactRootMap.get(element)
    if (!root) {
      root = createRoot(element)
      reactRootMap.set(element, root)
    }
    root.render(
      <ThemeProvider theme={sikaTheme}>
        <Component {...props} />
      </ThemeProvider>,
    )
  })
}
