import { createTheme } from '@mui/material'
import './global.scss'

const baseSikaTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      contrastText: "#fff",
    },
    secondary: {
      main: '#84EF84',
      contrastText: "#fff",
    },
    error: {
      main: '#EA1717',
    },
    gray1: {
      main: '#4D4D4D'
    },
    gray2: {
      main: '#949494'
    },
    gray3: {
      main: '#CCCCCC'
    },
    gray4: {
      main: '#E6E6E6'
    },
    gray5: {
      main: '#FAFAFA'
    },
    gray6: {
      main: '#F2F2F2'
    },
    mint: {
      main: '#CFF6D0'
    },
    honeydew: {
      main: '#E2FAE3'
    },
    statusBlue: {
      main: '#207CC3'
    },
    green1: {
      main: '#84EF84'
    },
  },
  typography: {
    fontFamily: 'Outfit',
    fontWeightMedium: 600,
  },
})

const smBreakpoint = baseSikaTheme.breakpoints.down('sm')
const headerTypography = {
  h1: {
    fontFamily: "Outfit !important",
    fontSize: '60px !important',
    [smBreakpoint]: {
      fontSize: '46px !important',
    },
    textDecoration: 'none',
  },
  h2: {
    fontFamily: "Outfit !important",
    fontSize: '46px !important',
    [smBreakpoint]: {
      fontSize: '35px !important',
    },
    textDecoration: 'none',
  },
  h3: {
    fontFamily: "Outfit !important",
    fontSize: '30px !important',
    [smBreakpoint]: {
      fontSize: '26px !important',
    },
    textDecoration: 'none',
  },
  h4: {
    fontFamily: "Outfit !important",
    fontSize: '20px !important',
    [smBreakpoint]: {
      fontSize: '18px !important',
    },
    textDecoration: 'none',
  },
}

const bodyTypography = {
  body1: {
    fontFamily: "Outfit !important",
    fontSize: '18px !important',
    textDecoration: 'none',
  },
  body2: {
    fontFamily: "Outfit !important",
    fontSize: '16px !important',
    textDecoration: 'none',
  },
  body3: {
    fontFamily: "Outfit !important",
    fontSize: '14px !important',
    textDecoration: 'none',
  },
  body4: {
    fontFamily: "Outfit !important",
    fontSize: '12px !important',
    textDecoration: 'none',
  },
}

const sikaTheme = createTheme(baseSikaTheme, {
  typography: {
    h1: headerTypography['h1'],
    h1Bold: {
      ...headerTypography['h1'],
      fontWeight: 700,
      [smBreakpoint]: {
        // @ts-expect-error
        ...headerTypography['h1'][smBreakpoint],
        fontWeight: 600,
      },
    },
    h2: headerTypography['h2'],
    h2Bold: {
      ...headerTypography['h2'],
      fontWeight: 700,
      [smBreakpoint]: {
        // @ts-expect-error
        ...headerTypography['h2'][smBreakpoint],
        fontWeight: 600,
      },
    },
    h3: headerTypography['h3'],
    h3Bold: {
      ...headerTypography['h3'],
      fontWeight: 700,
      [smBreakpoint]: {
        // @ts-expect-error
        ...headerTypography['h3'][smBreakpoint],
        fontWeight: 600,
      },
    },
    h4: headerTypography['h4'],
    h4Bold: {
      ...headerTypography['h4'],
      fontWeight: 700,
      [smBreakpoint]: {
        // @ts-expect-error
        ...headerTypography['h4'][smBreakpoint],
        fontWeight: 600,
      },
    },
    body1: bodyTypography['body1'],
    body1Bold: { ...bodyTypography['body1'], fontWeight: 600 },
    body2: bodyTypography['body2'],
    body2Bold: { ...bodyTypography['body2'], fontWeight: 600 },
    body3: bodyTypography['body3'],
    body3Bold: { ...bodyTypography['body3'], fontWeight: 600 },
    body4: bodyTypography['body4'],
    body4Bold: { ...bodyTypography['body4'], fontWeight: 600 },
    body: {
      fontFamily: "Outfit !important",
    },
    body3UnsetFont: {
      fontFamily: "unset !important",
    },
    body2UnsetFont: {
      fontSize: '14px !important',
    },
  },
  components: {
    // GLOBAL MUI STYLE OVERRIDES PER COMPONENT
    MuiAlert: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 10000,
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 10000,
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          zIndex: 10000,
        },
        icon: {
          zIndex: 1200,
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          boxShadow: 'unset',
          '&:focus': {
            backgroundColor: 'unset',
          },
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'unset',
          height: 'unset',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Outfit !important",
          fontSize: '14px !important',
          textTransform: 'none',
          color: baseSikaTheme.palette.gray2.main,
        },
      },
    },
    MuiIconButton: {
      // NOTE: this is being used right now for the sika dialog button
      // we might need to change this in the future depending on the use case
      variants: [
        {
          props: { color: 'inherit' },
          style: {
            backgroundColor: 'transparent !important',
            outline: 'unset !important',
            border: 'unset !important',
            backgroundImage: 'unset !important',
            fontFamily: 'Outfit !important',
            color: 'white !important',
            padding: '8px !important',
            borderRadius: '4px !important',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
              outline: 'unset !important',
              border: 'unset !important',
            },
            '&:focus': {
              backgroundColor: 'transparent !important',
              outline: 'unset !important',
              border: 'unset !important',
            },
          },
        }
      ]
    },
    MuiLoadingButton: {
      variants: [{
        props: { color: 'primary', variant: 'contained', size: 'large', disabled: true, loading: true },
        style: {
          color: 'transparent !important',
          backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        },
      }]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
      },
      variants: [{
          props: { color: 'secondary', variant: 'contained' },
          style: {
            outline: 'unset !important',
            border: 'unset !important',
            backgroundImage: 'unset !important',
            backgroundColor: `${baseSikaTheme.palette.secondary.main} !important`,
            color: 'white !important',
            fontFamily: 'Outfit !important',
            fontSize: '15px !important',
            '&:hover': {
              backgroundColor: `${baseSikaTheme.palette.secondary.main} !important`,
              color: 'white !important',
              border: 'unset !important',
              outline: 'unset !important',
            },
            '&:focus': {
              backgroundColor: `${baseSikaTheme.palette.secondary.main} !important`,
              color: 'white !important',
              border: 'unset !important',
              outline: 'unset !important',
            },
          },
        }, {
          props: { color: 'secondary', variant: 'contained', size: 'large' },
          style: {
            padding: '8px 22px !important',
            '&:hover': { padding: '8px 22px !important' },
            '&:focus': { padding: '8px 22px !important' },
          },
        }, {
          props: { color: 'secondary', variant: 'contained', size: 'large', disabled: true },
          style: {
            color: 'rgba(0, 0, 0, 0.26) !important',
            backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
          },
        }, {
          props: { variant: 'npsIcon' },
          style: {
            padding: '6px 16px !important',
            border: `1px solid ${baseSikaTheme.palette.gray4.main} !important`,
            outline: 'unset !important',
            borderRadius: '5px !important',
            backgroundImage: 'unset !important',
            fontFamily: 'Outfit !important',
            '&:hover': {
              outline: 'unset !important',
              borderColor: `${baseSikaTheme.palette.primary.main} !important`,
            },
            '&:focus': { 
              outline: 'unset !important',
              backgroundColor: `${baseSikaTheme.palette.mint.main} !important`
             },
            
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        // @ts-ignore
        paper: ({ ownerState }) => {
          return {
            borderRadius: ownerState.fullScreen ? '0px' : '8px'
          }
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          marginBottom: '0 !important',
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderLeft: '0 !important',
          borderTop: '0 !important',
          borderRight: '0 !important',
          backgroundColor: 'white !important',
        },
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body: "div",
          body1: "div",
          body2: "div",
          body3: "div",
          body4: "div",
          body3UnsetFont: "body3",
          body2UnsetFont: "div",
        }
      },
    },
    // MuiStepIcon: {
    //   styleOverrides: {
    //     text: {
    //       display: "none",
    //     },
    //   },
    // },
  }
})

export default sikaTheme
