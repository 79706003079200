// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "./node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "./node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Outfit:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,600,600italic,700,700italic,900italic,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{-webkit-font-smoothing:antialiased !important}", "",{"version":3,"sources":["webpack://./global.scss"],"names":[],"mappings":"AAAQ,KAER,6CACE","sourcesContent":["@import url(https://fonts.googleapis.com/css?family=Outfit:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,600,600italic,700,700italic,900italic,900);\n\nhtml {\n  -webkit-font-smoothing: antialiased !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
